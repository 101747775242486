import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Layout } from 'components/global'
import { MediaLinks, PageHero, Videos } from 'components/blocks'

const TeamPage = () => {
  const meta = {
    title: `Media | Dorothy Dandridge`,
  }
  return (
    <Layout title={meta.title}>
      <PageHero title="Media" />
      <section className="pt-10">
        <div className="w-11/12 mx-auto text-center space-y-3">
          <h2 className="text-[30px] md:text-[40px] xl:text-[50px] max-w-[1200px] mx-auto">
            <span className="text-gold">DOROTHY DANDRIDGE! THE MUSICAL</span>{' '}
            <br /> sweeps New York Theater Festival Awards!
          </h2>
          <p className="md:text-[20px] max-w-[1000px] mx-auto">
            The Musical closed out their run at the New York Theater Festival
            with five nominations for their stellar performances that ended in
            Dec 2023 at the Off Broadway LaTea Theatre. Nominations include Best
            Director (Tamara Tunie), Best Choreographer (Byron Easley), Best
            Singer (N&rsquo;Kenge and&nbsp;
            <a
              href="https://www.broadwayworld.com/people/Ronnie-S-Bowman-Jr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ronnie S. Bowman
            </a>
            , Jr), and Best Production. The show took home the following wins:
            <strong>&nbsp;Best Director</strong>&nbsp;(Tamara Tunie),&nbsp;
            <strong>Best Choreographer</strong>&nbsp;(Byron Easley), and&nbsp;
            <strong>Best Singer&nbsp;</strong>(N&rsquo;Kenge).&nbsp;
            <em>Dorothy Dandridge! The Musical&nbsp;</em>was the only show with
            the most nominations and most wins this season at the New York
            Theater Festival!
          </p>
        </div>
      </section>
      <Videos />
      <div className="bg-black pb-12">
        <div className="w-11/12 mx-auto container grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-0">
          <div className="w-[90%] mx-auto">
            <StaticImage
              src="../../src/images/gallery/media-image-1.png"
              alt=""
            />
          </div>
          <div className="w-[90%] mx-auto">
            <StaticImage
              src="../../src/images/gallery/media-image-2.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <MediaLinks />
    </Layout>
  )
}

export default TeamPage
